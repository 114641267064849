import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-mod-help',
  templateUrl: './mod-help.page.html',
  styleUrls: ['./mod-help.page.scss'],
})
export class ModHelpPage {

  constructor(
    private modalCtrl: ModalController,
  ) { }


  /**
   * ------------------------------------------------------------------------------------
   */
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }
}
