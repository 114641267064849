import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchItem'
})
export class SearchItemPipe implements PipeTransform {

  /**
   * Search a filterString in the name and keywords, all in lowercase.
   */
  transform(value: Array<any>, filterString: string ) { // , fkHeadquarter: string
    // let fkOk = true;
    const filteredItems = []; // Empty
    for ( let i = 0, fini = value.length; i < fini; i++ ) {
      let inName = true;
      if ( filterString !== '' && filterString != null ) {
        inName = value[i].name.toLowerCase().indexOf( filterString.toLowerCase() ) > -1; // In name
        if ( !inName ) { // Not the name, checking the employee
          inName = value[i].employee.toLowerCase().indexOf( filterString.toLowerCase() ) > -1; // In name
        }
      }
      // fkOk = ( fkHeadquarter === '0' || fkHeadquarter === value[i].fk ) ? true : false;
      // && fkOk
      if ( inName ) { // || inLastName
        filteredItems.push( value[i] ); // Condition is ok
      }
    }
    return filteredItems;
  }

}
