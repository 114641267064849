import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectEmployee'
})
export class SelectEmployeePipe implements PipeTransform {

  transform(value: Array<any>, filterString: string, field): any {
    const filteredItems = []; // Nada

    for ( let i = 0, fini = value.length; i < fini; i++ ) {
      let inName = true;
      if ( field === 'e' && filterString !== '' && filterString != null ) {
        inName = value[i].e.toLowerCase().indexOf( filterString.toLowerCase() ) > -1;
      } else if ( field === 'name' ) {
        inName = value[i].name.toLowerCase().indexOf( filterString.toLowerCase() ) > -1;
      }
      if ( !inName ) {
        inName = value[i].uk.toLowerCase().indexOf( filterString.toLowerCase() ) > -1;
      }
      if ( inName ) { // || inLastName
        filteredItems.push( value[i] ); // Condition is ok
      }
    }
    return filteredItems;
  }
}
