import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataService } from 'src/app/services/data.service';
import { IEmployee } from 'src/app/interfaces/iemployee';

@Component({
  selector: 'app-mod-sel-employee',
  templateUrl: './mod-sel-employee.page.html',
  styleUrls: ['./mod-sel-employee.page.scss'],
})
export class ModSelEmployeePage {

  currentEmployees: any = [];
  filterString = '';
  title = '';

  employee: IEmployee = {
    id: '0',
    name: '',
    email: ''
  };

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private authService: AuthenticationService,
    private dataService: DataService,
  ) {
    console.table(this.navParams);
  }

  /**
   * ------------------------------------------------------------------------------------
   */
  ionViewWillEnter() {
    this.employee.id = '0';
    this.employee.name = '';
    this.employee.email = '';

    // console.table(this.navParams);
    this.title = this.navParams.data.paramTitle;

    this.currentEmployees = [];
    this.loadEmployees();
  }

  /**
   * ------------------------------------------------------------------------------------
   */
  loadEmployees() {
    const postParams = { op: 'employees' }; // Params of data
    const paramPost = {}; // Empty
    const userData = this.authService.getUserPostData();
    Object.assign(paramPost, userData, postParams);

    this.dataService.postData2( paramPost )
    .then( result => {
        const objData = JSON.parse(JSON.stringify(result));
        if ( Array.isArray( objData ) ) {
          this.currentEmployees = Object.create(objData); // TODO: review
        } else if ( typeof objData === 'object' && objData.error ) {
          // this.toastWarning( objData.error );
        }
      },
      err => {
        // Connection failed message
        // this.toastWarning('Error connecting to the server: hr-Employees');
      }
    );
  }

  /**
   * ------------------------------------------------------------------------------------
   */
  async chosenEmployee( selEmployee ) {
    // console.table( selEmployee );
    await this.modalCtrl.dismiss( selEmployee );
  }

  /**
   * ------------------------------------------------------------------------------------
   */
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

}
