import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class AppSettings {

    public static SETTING_DEV_MODE = true;
    public static APP_NAME = 'EMERSONP2';
    public static APP_VERSION = '0.0.3';

    // INSTALL PARKING:
    swDevMode = false;

    /**
     * Return the API url depending if we are in develop mode
     * ----------------------------------------------------------------------
     */
    getApiParkingEndPoint() {
        return ( this.isDevelopmentMode() ) ?
            'http://localhost/Parking2/api/' : 'https://emerson-parking2.m-robotic.com/api/';
    }

    /**
     * Return if we are working in development mode
     * ----------------------------------------------------------------------
     */
    isDevelopmentMode() {
        return this.swDevMode;
    }
}
