import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchPInfo'
})
export class SearParkingInfoPipe implements PipeTransform {

  transform(value: Array<any>, filterString: string): any {
    const filteredItems = []; // Nada

    for (let i = 0, fini = value.length; i < fini; i++) {
      let inName = true;
      if (filterString !== '' && filterString != null) {
        inName = value[i].pname.toLowerCase().indexOf(filterString.toLowerCase()) > -1;
        if (!inName) { // No in pname, searh in e
          inName = value[i].e.toLowerCase().indexOf(filterString.toLowerCase()) > -1;
        }
      }
      if (inName) { // || inLastName
        filteredItems.push(value[i]); // Condition is ok
      }
    }
    return filteredItems;
  }

}
