import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchLog'
})
export class SearchLogPipe implements PipeTransform {

  transform(value: Array<any>, filterString: string ) {
    const filteredItems = []; // Empty
    for ( let i = 0, fini = value.length; i < fini; i++ ) {
      let inName = true;
      if ( filterString !== '' && filterString != null ) {
        inName = value[i].info.toLowerCase().indexOf( filterString.toLowerCase() ) > -1; // In name
        if ( !inName ) { // Not the info, checking the employee
          inName = value[i].emp.toLowerCase().indexOf( filterString.toLowerCase() ) > -1; // In name
        }
      }
      if ( inName ) { // || inLastName
        filteredItems.push( value[i] ); // Condition is ok
      }
    }
    return filteredItems;
  }

}
