import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage';
import { AppSettings } from './../app.settings';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  url = '';
  apiKey = 'KLJDBK&#&#&JSJSJ';
  // https://stackoverflow.com/questions/54304481/ionic-4-angular-7-passing-object-data-to-another-page
  extras: any;

  // private userDetails: any;
  private userPostData = { user_id: 0, token: '' };

  /**
   * Constructor of the Service with Dependency Injection
   * @param http The standard Angular HttpClient to make requests
   * ---------------------------------------------------------------------------------
   */
  constructor(
    private http: HttpClient,
    private storage: Storage,
    private appSettings: AppSettings
  ) {
    this.url = this.appSettings.getApiParkingEndPoint(); // Local or server
  }

  /**
   *
   * @param data
   * ---------------------------------------------------------
   */
  setExtras(data) {
    this.extras = data;
  }

  /**
   *
   * ---------------------------------------------------------
   */
  getExtras() {
    return this.extras;
  }

  /**
   * Check if the user is Admin
   * ---------------------------------------------------------
   */
  checkIsAdmin() {
    const postParams = { op: 'isAdmin' }; // Params of data
    const paramPost = {}; // Empty
    const userData = this.getUserPostData();
    Object.assign(paramPost, userData, postParams);

    return new Promise((resolve, reject) => {
      const url = this.url + 'op';
      // const myHeaders = new HttpHeaders();
      this.http.post(url, JSON.stringify(paramPost))
        .subscribe(res => {
          const objData = JSON.parse(JSON.stringify(res));
          resolve(objData.isAdmin);
        // tslint:disable-next-line: no-unused-expression
        }), (err: any) => {
          reject(err);
        };
    });
  }

  /**
   * Send a post data without user_id, only for public pages
   * @param urlApi /url to the API
   * @param postParams JSON data
   * ---------------------------------------------------------
   */
  postData(urlApi: string, postParams) {

    // console.log('=== DataService postData ===', postParams, urlApi);

    return new Promise((resolve, reject) => {
      const url = this.url + urlApi;
      // const myHeaders = new HttpHeaders();
      this.http.post(url, JSON.stringify(postParams))
        .subscribe(res => {
          resolve(res);
          // tslint:disable-next-line: no-unused-expression
        }), (err: any) => {
          reject(err);
        };
    });
  }

  /**
   * Send a post data without user_id, only for public pages
   * @param postParams JSON data
   * ---------------------------------------------------------
   */
  postData2(postParams) {

    // console.log('=== DataService postData ===', postParams, urlApi);

    return new Promise((resolve, reject) => {
      const url = this.url + 'op';
      // const myHeaders = new HttpHeaders();
      this.http.post(url, JSON.stringify(postParams))
        .subscribe(res => {
          resolve(res);
          // tslint:disable-next-line: no-unused-expression
        }), (err: any) => {
          reject(err);
        };
    });
  }


  /**
   * Send a post data
   * @param urlApi /url to the API
   * @param postParams User data, like { 'fke': '5' }, require user_id, token
   * ---------------------------------------------------------
   */
  postDataPrivate(urlApi: string, postParams) {
    const paramPost = {};
    const userData = this.getUserPostData();
    Object.assign(paramPost, userData, postParams);
    // console.log('=== Auth service, postDataPrivate: ===', paramPost);
    // debugger;
    const myHeaders = new HttpHeaders();
    const url = this.url + urlApi;
    if (userData.user_id === 0) {
      // console.log('===== ERROR: sin user_id ====');
      return null;
    } else {
      return this.http.post(url, JSON.stringify(paramPost), { headers: myHeaders });
    }
  }

  /**
   * Send a post data
   * @param endPoint string /url to the API
   * @param postParams Json with the data, sometimes {} others a lot of info
   * ---------------------------------------------------------
   */
  postDataSubscribe(endPoint: string, postParams) {
    const paramPost = {};
    const userData = this.getUserPostData();
    Object.assign(paramPost, userData, postParams);

    // console.log( 'DS/postDataSubscribe', endPoint, paramPost );

    return new Promise((resolve, reject) => {
      const myHeaders = new HttpHeaders();
      const url = this.url + endPoint;

      this.http.post(url, JSON.stringify(paramPost), { headers: myHeaders })
        .subscribe(res => {
          resolve(res);
        });
      /*
      this.http.post(url, JSON.stringify(paramPost), { headers: myHeaders })
        .subscribe(res => {
          resolve(res);
        }), (err) => { // TODO: review why these lines doesn't work
          reject(err);
        }
        */
    });
  }

  /**
   * @param data
   * ------------------------------------------------------------------------
   */
  setLocalData(data) {
    // debugger;
    this.userPostData = data.userData; // Getting the user information
    // console.log('=== DATA service - userDetails: ===', this.userPostData);
    this.userPostData.user_id = this.userPostData.user_id;
    this.userPostData.token = this.userPostData.token;
    localStorage.setItem('userData', JSON.stringify(this.userPostData)); // userData, our data
    this.storage.set('currentUser', JSON.stringify(this.userPostData)); // From another example
  }

  /**
   *
   * ------------------------------------------------------------------------
   */
  getUserPostData() {
    this.userPostData = JSON.parse(localStorage.getItem('userData')); // TODO: why we need to read the token???
    return this.userPostData;
  }


}
