import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataService } from 'src/app/services/data.service';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-mod-profile',
  templateUrl: './mod-profile.page.html',
  styleUrls: ['./mod-profile.page.scss'],
})
export class ModProfilePage implements OnInit {

  employee = {
    pass: '',
    name: ''
  };

  constructor(
    private authService: AuthenticationService,
    private dataService: DataService,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
  }

  /**
   * ------------------------------------------------------------------------
   */
  ionViewWillEnter() {
    this.employee = { pass: '', name: '' };
    this.loadData();
  }

  /**
   * ----------------------------------------------------------------------
   */
  loadData() {

    const postParams = { op: 'userName' }; // Params of data
    const paramPost = {}; // Empty
    const userData = this.authService.getUserPostData();
    Object.assign(paramPost, userData, postParams);

    this.dataService.postData2(paramPost)
      .then(result => {
        const objData = JSON.parse(JSON.stringify(result));
        console.log('DATA: ', objData);
        if (typeof objData === 'object') {
          if (objData.error) {
            this.toastWarning(objData.error);
          } else {
            const obj = Object.create(objData);
            this.employee.name = obj.name;
          }
        } else {
          this.toastWarning('Error loading user name data');
        }
      },
        err => {
          // Connection failed message
          this.toastWarning('Error connecting to the server user name ' + err);
        }
      );
  }

  /**
   * ------------------------------------------------------------------------------------
   */
  updatePassword() {

    if (this.employee.pass.length < 6) {
      this.toastWarning('Please, insert at least 6 characters');
      return;
    }

    const postParams = { op: 'employeeUpPass', pass: this.employee.pass }; // Params of data
    const paramPost = {}; // Empty
    const userData = this.authService.getUserPostData();
    Object.assign(paramPost, userData, postParams);

    this.dataService.postData2(paramPost)
      .then(result => {
        const objData = JSON.parse(JSON.stringify(result));
        if (objData.text) {
          this.toastWarning(objData.text); // Message back
        } else {
          this.toastWarning(objData.error); // Message back
        }
      },
        err => {
          this.toastWarning('Error connecting to the server: up-em-pass' + err);
          console.log(err);
          // this.loadExtraData(); // Countries, list of Agreement list
        }
      );
  }

  /**
   * ------------------------------------------------------------------------------------
   */
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  /**
   * ------------------------------------------------------------------------------------
   */
  async toastWarning(msg: string) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }

}
