import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataService } from 'src/app/services/data.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-mod-maps',
  templateUrl: './mod-maps.page.html',
  styleUrls: ['./mod-maps.page.scss'],
})
export class ModMapsPage {

  mapList: any = [];

  constructor(
    private modalCtrl: ModalController,
    private authService: AuthenticationService,
    private dataService: DataService,
  ) { }

  /**
   * ------------------------------------------------------------------------------------
   */
  ionViewWillEnter() {
    this.loadMaps();
  }

  /**
   * ------------------------------------------------------------------------------------
   */
  loadMaps() {
    const postParams = { op: 'maps' }; // Params of data
    const paramPost = {}; // Empty
    const userData = this.authService.getUserPostData();
    Object.assign(paramPost, userData, postParams);

    this.dataService.postData2(paramPost)
      .then(result => {
        const objData = JSON.parse(JSON.stringify(result));
        if (Array.isArray(objData)) {
          this.mapList = objData;
        } else if (typeof objData === 'object' && objData.error) {
          // this.toastWarning( objData.error );
        }
      },
        err => {
          // Connection failed message
          // this.toastWarning('Error connecting to the server: hr-Employees');
        }
      );
  }
  /**
   * ------------------------------------------------------------------------------------
   */
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

}
