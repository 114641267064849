import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModSelEmployeePageRoutingModule } from './mod-sel-employee-routing.module';
import { ModSelEmployeePage } from './mod-sel-employee.page';

import { PipesModule } from './../../pipes.modules';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ModSelEmployeePageRoutingModule,
    PipesModule
  ],
  declarations: [ModSelEmployeePage]
})
export class ModSelEmployeePageModule {}
