import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SearchItemPipe } from './pipes/search-item.pipe';
import { SearchLogPipe } from './pipes/search-log.pipe';
import { SelectEmployeePipe } from './pipes/select-employee.pipe';
import { SearParkingInfoPipe } from './pipes/sear-parking-info.pipe';

@NgModule({
  declarations: [SearchItemPipe, SelectEmployeePipe, SearchLogPipe, SearParkingInfoPipe],
  imports: [IonicModule],
  exports: [SearchItemPipe, SelectEmployeePipe, SearchLogPipe, SearParkingInfoPipe ]
})
export class PipesModule {

}
